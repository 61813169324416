import { Fade } from '@material-ui/core';
import { makeStyles, fade } from '@material-ui/core/styles';

const drawerWidth = 0;

export default makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none',
    backgroundImage: 'linear-gradient(var(--color-accent-orange), var(--color-accent-purple))',
    backgroundColor: 'transparent',
    paddingBottom: '1rem',
    paddingTop: '1rem',
    borderBottomLeftRadius: '30px',
    borderBottomRightRadius: '30px',
  },
  title: {
    flexGrow: 1,
    alignItems: 'center',
    display: 'flex',
    textDecoration: 'none',
    [theme.breakpoints.up('sm')]: {
      minWidth: '200px'
    },
  },
  image: {
    padding: '4px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchInput: {
    '& > input': {
      color: 'var(--color-text-light)',
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      //transition: 'all .2s ease-in-out' ,
      [theme.breakpoints.up('xs')]: {
        width: '3.2rem',
        fontSize: '.8rem',
        '&:focus': {
          width: '4.2rem',
          fontSize: '1rem',
        },
      },
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    }
  },
  header: {
    padding: '1rem',
    fontSize: '1rem',
    boxShadow: 'none',
    backgroundColor: 'var(--color-accent-green)',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    '& > *': {
      color: 'var(--color-text-light)',
    },
  },
  menu:{
    paddingTop: '2rem',
    backgroundColor: 'var(--color-accent-pink)',
    height: '100%',
  },
  menuItem:{
    fontSize: '1.2rem',
    padding: '2rem 4rem',
    transition: 'all .2s ease-in-out' ,
    '&:hover': {
      backgroundColor: 'var(--color-accent-purple)',
      color: 'white',
      opacity: '70%'
    },
  },
}));
