import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { CssBaseline } from '@material-ui/core';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import GlobalStyle from "./components/styles/GlobalStyle";
import { Navbar, Products, Cart, Checkout } from './components';
import LoaderContainer from "./components/UI/Loader";
import { ErrorContext } from './global/contexts';
import Error from './components/Error';
import Contact from './components/Contact/contact';
import About from './components/About/about';
import SupportSuccess from './components/Contact/support-success';
import OrganizationProducts from './components/Organizations/OrganizationProducts';
import Demo from './components/Contact/demo';
import Footer from './components/Footer/Footer';
import Organizations from './components/Organizations/Organizations';
import Success from './components/CheckoutForm/success';
import Cancel from './components/CheckoutForm/cancel';
import Pay from './components/CheckoutForm/pay';
import NotFound from './components/NotFound';
import ReactGA from "react-ga4";
import Campaign from './components/Campaign/campaign';

const LoadOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-background-white);
`;

const Page = styled.div`
   min-height: calc(100vh - 314px);
`;

const App = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const [cartQuantity, setCartQuantity] = useState(0);
  const TRACKING_ID = 'G-DX3697LX0L';

  ReactGA.initialize(TRACKING_ID);
  ReactGA.send({ hitType: "pageview", page: document.location.pathname, title: document.location.pathname });

  const fetchCart = async () => {
    let cartItems = JSON.parse(localStorage.getItem('items')) ?? [];
    setCartItems(cartItems);
  };

  const handleAddToCart = async (product, quantity) => {
    let cartItems = JSON.parse(localStorage.getItem('items')) ?? [];
    let cartItem = cartItems?.find(x => x.productId == product.id);
    if (cartItem){
      cartItem.quantity = cartItem.quantity + quantity;
    }
    else{
      let item = {
        "productId": product.id, 
        "productName": product.displayName, 
        "price": product.price, 
        "organization": product.organization.name,
        "quantity": quantity
      };
      cartItems.push(item);
    }    
    localStorage.setItem('items', JSON.stringify(cartItems));
    setCartItems(cartItems);

    // Clear messages
    localStorage.setItem('messages', JSON.stringify([]))
  };

  const handleUpdateCartQty = async (productId, quantity) => {
    if (quantity == 0){
      handleRemoveFromCart(productId);
    }
    let cartItems = JSON.parse(localStorage.getItem('items')) ?? [];
    let cartItem = cartItems?.find(x => x.productId == productId);
    if (cartItem){
      cartItem.quantity = quantity;
    }    
    localStorage.setItem('items', JSON.stringify(cartItems));
    setCartItems(cartItems);

    // Clear messages
    localStorage.setItem('messages', JSON.stringify([]))
  };

  const handleRemoveFromCart = async (productId) => {
    let cartItems = JSON.parse(localStorage.getItem('items')) ?? [];
    let cartItem = cartItems?.find(x => x.productId == productId);
    if (cartItem){
      cartItems = cartItems.filter(x => x.productId !== productId);
    }    
    localStorage.setItem('items', JSON.stringify(cartItems));
    setCartItems(cartItems);

    // Clear messages
    localStorage.setItem('messages', JSON.stringify([]))
  };

  const handleEmptyCart = async () => {
    localStorage.setItem('items', JSON.stringify([]))
    setCartItems([]);

    // Clear messages
    localStorage.setItem('messages', JSON.stringify([]))
  };

  useEffect(async () => {
    setLoading(true);
    fetchCart();
    setLoading(false);
  }, []);

  // Set cart quantity for toolbar
  useEffect(() => {
    let quantity = 0;
    if (cartItems.length > 0){      
      quantity = cartItems?.map(item => item.quantity)?.reduce((prev, next) => prev + next) ?? 0; 
    }
    setCartQuantity(quantity);
  }, [cartItems]);

  const onSearch = (value) => {
    setSearch(value);
  }

  const updateError = async ( error ) => {
    setErrorMessage(error);    
  };

  return (
    <Router>
        <GlobalStyle />
          <CssBaseline />
          {loading && (
            <LoadOverlay>
              <LoaderContainer />
            </LoadOverlay>
          )}
              <ErrorContext.Provider value={{ errorMessage, updateError }}>
                <Error/>
                <Navbar totalItems={cartQuantity} onSearch={onSearch}/>
                <Page>
                    <Switch>
                      <Route exact path="/">
                        <Organizations search={search}/>
                      </Route>
                      {/* <Route exact path="/products">
                        <Products onAddToCart={handleAddToCart}/>
                      </Route> */}
                      <Route exact path="/products/:organizationSlug">
                        <OrganizationProducts onAddToCart={handleAddToCart} search={search}/>
                      </Route>
                      <Route exact path="/cart">
                        <Cart cartItems={cartItems} onUpdateCartQty={handleUpdateCartQty} onRemoveFromCart={handleRemoveFromCart} onEmptyCart={handleEmptyCart} />
                      </Route>
                      <Route exact path="/checkout">
                        <Checkout cartItems={cartItems}/>
                      </Route>
                      <Route exact path="/over-ons">
                        <About/>
                      </Route>
                      <Route exact path="/contact">
                        <Contact/>
                      </Route>
                      <Route exact path="/demo">
                        <Demo/>
                      </Route>
                      <Route exact path="/support-success">
                        <SupportSuccess/>
                      </Route>
                      <Route exact path="/success">
                        <Success/>
                      </Route>
                      <Route exact path="/cancel" >
                        <Cancel/>
                      </Route>
                      <Route exact path="/order/:id/pay">
                        <Pay/>
                      </Route>
                      <Route exact path="/campagne/:slug">
                        <Campaign/>
                      </Route>
                      <Route path="*" component={NotFound} />
                    </Switch>
                </Page>
                <Footer/>
              </ErrorContext.Provider>
    </Router>
  );
};

export default App;
