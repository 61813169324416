import axios from "axios";

export const getAxiosClient = async () => {
  //const token = await getToken();
  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
   // headers: { common: { Authorization: `Bearer ${token}` } },
  });

  return axiosClient;
};
