import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Page from "../UI/Elements/Page";
import { UseErrorContext } from '../../global/contexts';
import { getCampaign } from '../../api/services/campaign';
import thumbnail from "../../assets/no-thumbnail.jpg";
import { LocationOn } from '@material-ui/icons';
import { Grid, Card, CardMedia, CardContent, CardActions, IconButton, Fade } from '@material-ui/core';
import useStyles from './styles';
import Pagination from "../Pagination";

  const Campaign = () => {
    const { slug } = useParams();
    const classes = useStyles();
    const [campaign, setCampaign] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const { updateError } = UseErrorContext();

    useEffect(() => {
      const refresh = async () => {
        try{        
          setLoading(true);
          const data = await getCampaign(slug);   
          console.log(data)  
          setCampaign(data);
    
          setLoading(false);
        } catch (err) {
          updateError(err);
        }
      };
  
      refresh();
    }, [currentPage]);

  return (
    <Page noBackground>
      {!campaign 
      ? <h1>Campagne niet gevonden</h1> 
      :
      <>
      <div className="page__header">
        <h1>Campagne</h1>
      </div>
        <div className="page__inner">
          <div>
            <h3>Naam: {campaign?.name}</h3>
            <h3>Deelnemers:</h3>
            <div className={classes.content}>         
            <Grid container alignItems="stretch" className={classes.grid}>
              {campaign.participants.map((participant, idx) => (
                <Grid key={participant.id} item xs={6} sm={6} md={3} lg={3}>
                  <Card className={classes.root}>
                    <CardMedia className={classes.media} image={participant.logoUrl ?? thumbnail} title={participant.name} />
                    <CardContent>
                      <div className={classes.cardContent}>
                        <h3>
                          {participant.name}
                        </h3>
                      </div>
                      <IconButton className={classes.iconButton} size="small">
                        <LocationOn/> 
                        <span>{participant.city} ({participant.postalCode})</span>
                      </IconButton>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
          </div>
          </div>
        </div>
      </>
      }
    </Page>
  );
};

export default Campaign;
