export const GetPagination = (headers) => {   
  if (headers["x-pagination"] === undefined) 
    throw new Error("x-pagination not found in header")
  let pagination = JSON.parse(headers["x-pagination"]);
  return pagination;
  };

  export const getLineTotalPrice = (item) => {
    return item.quantity * item.price;
  } 

  export const getTotalPrice = (cartItems) => {
    let totalPrice = 0;
    cartItems.forEach((item) => {
      totalPrice += item.price * item.quantity;
    });
    return totalPrice;
  } 

  export const getTransactionPrice = (cartItems) => {
    const price = (getTotalPrice(cartItems) * 0.02);
    return Math.round((price + Number.EPSILON) * 100) / 100;
  } 

  export const getTotalTransactionIncluded = (cartItems) => {
    const totalPrice = getTotalPrice(cartItems);
    console.log("TOTAL PRICE", totalPrice)
    const transActionPrice = getTransactionPrice(cartItems);
    console.log("transActionPrice", transActionPrice)
    console.log(totalPrice + transActionPrice)
    return totalPrice + transActionPrice;
  } 

  export const ValidateEmail = function(email) {
    return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  };