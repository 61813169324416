import React, { useState, useEffect } from 'react';
import { Button, Grid, Typography, MenuItem, Checkbox } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import useStyles from './styles';
import FormInput from '../UI/Elements/CustomTextField';
import ReactHookFormSelect from '../UI/Elements/ReactHookFormSelect';
import { UseErrorContext } from '../../global/contexts';
import { getCountries } from '../../api/services/organization';
import { checkVat } from '../../api/services/order';
import Loader from "../UI/Loader";

const InvoiceForm = ({ backStep, nextStep}) => {
  const methods = useForm();
  const [countries, setCountries] = useState([]);
  const [invoiceData, setInvoiceData] = useState(JSON.parse(localStorage.getItem('customer')) ?? {});
  const { updateError } = UseErrorContext();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const refresh = async () => {
      try{        
        const data = await getCountries();        
        setCountries(data);
      } catch (err) {
        updateError(err);
      }
    };

    refresh();
  }, []);

  const setCustomerCache = async (data) => {
    localStorage.setItem('customer', JSON.stringify(data));
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function isValidVat(vat) {
    const vatNumberRegex = /^(AT|BE|BG|HR|CY|CZ|DK|EE|FI|FR|DE|GR|HU|IE|IT|LV|LT|LU|MT|NL|PL|PT|RO|SK|SI|ES|SE)[0-9A-Za-z]{2,12}$/;
    return vatNumberRegex.test(vat);
  }

  const _checkVat = async (vat) => {
    const isValid = await checkVat(vat);
    return isValid;
  };

  const submit = async (data) => {
    setLoading(true);

    if (data.vat){
      data.vat = data.vat.replaceAll(/\s/g, "");
      data.vat = data.vat.replaceAll(".", "");
    }

    setCustomerCache(data); 

    try{
      if (!isValidEmail(data.email)){
        throw({"message": "Ongeldig e-mailadres"});
      }

      if (data.email != data.email2){
        throw({"message": "E-mailadressen komen niet overeen"});
      }

      if ((!data.company && data.vat) || (data.company && !data.vat))
        throw({"message": "Gelieve zowel Bedrijfsnaam als BTW-nummer in te vullen"});

      if (data.vat){
        if (!isValidVat(data.vat))
          throw({"message": "Ongeldig btw-nummer formaat, gelieve zeker de landcode vooraan mee te geven"});

        // var isValid = await _checkVat(data.vat);
        // if (!isValid){
        //   var isValid2 = await _checkVat(data.vat);
        //   if (!isValid2){
        //     throw({"message": "Ongeldig btw-nummer"});
        //   }
        // }
      }

      nextStep();
    }
    catch(err){
      updateError(err);
    }
    finally{
      setLoading(false);
    }

  };

  return (
    <>
      <h2>Factuurgegevens</h2>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit((data) => {submit(data)})}>
          <Grid container spacing={3}>
            <FormInput name="company" label="Bedrijfsnaam" defaultValue={invoiceData.company}/>
            <FormInput name="vat" label="BTW-nummer" defaultValue={invoiceData.vat}/>
            <FormInput required name="firstName" label="Voornaam" defaultValue={invoiceData.firstName}/>
            <FormInput required name="lastName" label="Achternaam" defaultValue={invoiceData.lastName} />
            <FormInput required name="email" label="E-mailadres" defaultValue={invoiceData.email} />
            <FormInput required name="email2" label="Herhaal e-mailadres" />
            <FormInput required name="street" label="Straat + huisnummer" defaultValue={invoiceData.street} />
            <FormInput required name="city" label="Gemeente/Stad" defaultValue={invoiceData.city} />
            <FormInput required name="zip" label="Postcode" defaultValue={invoiceData.zip} />       
            <ReactHookFormSelect required id="country" name="country" label="Land" defaultValue={invoiceData.country}>
              {countries.map((country, idx) => (
                <MenuItem key={idx} value={country.code}>{country.name}</MenuItem>
              ))}
            </ReactHookFormSelect>
            <Grid item xs={12}>
              <Checkbox required/>
              <span>Ik ga akkoord met de <a href="https://stallesbon.blob.core.windows.net/public/TERMSANDCONDITIONS.pdf" target="_blank" className={classes.link}>Algemene Voorwaarde</a> en <a href="https://stallesbon.blob.core.windows.net/public/PRIVACYPOLICY.pdf" target="_blank" className={classes.link}>Privacy policy</a></span>
            </Grid>
          </Grid>
          <br />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            { loading
              ? <Loader center />
              :
                <>
                  <Button component={Link} variant="outlined" onClick={() => backStep()}>Terug</Button>
                  <Button type="submit" className="customButton">Verder</Button>
                </>
              } 
            </div>
          
        </form>
      </FormProvider>
    </>
  );
};

export default InvoiceForm;
