import { getAxiosClient } from "../axios";

export const getCampaign = async (slug) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/campaigns/slug/${slug}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

