import React from "react";
import styled from "styled-components";
import background from "../../../assets/bg8.png"

const Body = styled.div`
  width: 100%;
  background-image: ${(props) => (props.noBackground ? "" : `url("${background}")`)};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const InnerBody = styled.div`
  width: ${(props) => (props.width ? "100%" : "90%")};
  margin: 10rem auto 0 auto; 
  margin-top: ${props => props.noHeader ? "2rem" : "8rem"};
  padding: ${(props) => (props.width ? "0" : "0px 0 0 0")};
  max-width: ${(props) => (props.width ? "100%" : "120rem")};
  .page__header {
    display: flex;
    align-content: center;
    justify-content: space-between;
    & .page__header__sub {
      ul {
        display: flex;
        li {
          margin-left: 1rem;
        }
      }
    }
  }
`;

const Page = ({ fullWidth, children, noBackground, noHeader }) => {
  return <Body noBackground={noBackground}>
    <InnerBody width={fullWidth} noHeader={noHeader}>
      {children}
    </InnerBody>
    </Body>;
};

export default Page;
