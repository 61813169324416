import React from "react";
import { DemoForm } from "./Components/DemoForm";
import { useHistory } from "react-router-dom";
import Page from "../UI/Elements/Page";
import { Box } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
   // backgroundImage: `url(${background})`,
    backgroundSize: 'cover'
  },
  paper: {
    padding: '4rem',
    borderRadius: '30px',
    margin: 'auto',
    maxWidth: '80%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      padding: '3rem',
   },
  },
  actionButton: {
    margin: '1rem 0'
  }
}));

const Demo = () => {
  const classes = useStyles();
  const history = useHistory();

  const theme = {
    spacing: 8,
  }

  return (
    <div className={classes.root}>
      <Page>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <h1>Benieuwd hoe AllesBon werkt?</h1> 
              <h3>Boek vandaag nog je gratis demo!</h3>
              <Box my={4}>
                <p>Vul je gegevens in en laat eventueel een opmerking of je gewenste datum/tijdstip achter. Wij contacteren je dan zo snel mogelijk om een online demo of bij jou ter plekken in te plannen.</p>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <DemoForm onClose={() => history.go(0)}/>
            </Grid>
          </Grid>
        </Paper>
      </Page>
    </div>
  );
};

export default Demo;
