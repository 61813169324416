import React from 'react';
import { Link } from 'react-router-dom';
import Page from './UI/Elements/Page';

const NotFound = () => (
  <Page noBackground>
    <h1>Helaas, deze pagina niet gevonden</h1>
    <Link to="/">Ga naar home</Link>
  </Page>
);

export default NotFound;