import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Badge, MenuItem, Menu, Typography, Drawer } from '@material-ui/core';
import { ShoppingCart, FavoriteBorder, StarBorder, FlashOn, Cancel, Search } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/logov4.png';
// KERST
//import logo from '../../assets/logoKerst.png';
import useStyles from './styles';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from "react-router-dom";
import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';

const PrimarySearchAppBar = ({ totalItems, onSearch }) => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState(null);
  const classes = useStyles();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openMenu, setOpenMenu] = useState(false);
  const [auth, setAuth] = React.useState(false); // TODO get this value from authentication

  const onChangeSearch = async (event) => {
    setSearchValue(event.target.value);
  };

  const confirmOnEnter = async (event) => {
    if (event.key === "Enter"){
      onSearch(searchValue);
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpenMenu(false);
  };

  const OpenHomePage = () => {
    handleClose();
    history.push("/");
  }
  const OpenAboutPage = () => {
    handleClose();
    history.push("/over-ons");
  }
  const OpenContactPage = () => {
    handleClose();
    history.push("/contact");
  }
  const OpenDemoPage = () => {
    handleClose();
    history.push("/demo");
  }

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        {/* { (location.pathname === '/' || location.pathname.startsWith("/products"))&&
          <div className={classes.header}>
            <IconButton className={classes.iconButton} size="small" onClick={() => OpenAboutPage()}>
              <FlashOn/> 
              Wil jij ook jouw cadeaubonnen hier verkopen?
              <FlashOn/> 
            </IconButton>
          </div>
          
        } */}
        <Toolbar>
          <Typography component={Link} onClick={() => {OpenHomePage(); window.location.reload();}} variant="h6" className={classes.title} color="inherit">
            <img src={logo} alt="AllesBon" height="50px" className={classes.image} />
            {/* <h2>ALLES BON</h2> */}
          </Typography>
          <div className={classes.grow} />
          { location.pathname === '/' && (
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                onChange={(event) => {onChangeSearch(event)}} 
                onKeyDown={(event) => {confirmOnEnter(event)}}
                placeholder="Zoeken..."
                className={classes.searchInput}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>   
          )}
            <IconButton component={Link} to="/cart" aria-label="Show cart items" color="inherit">
              <Badge badgeContent={totalItems} color="secondary">
                <ShoppingCart />
              </Badge>
            </IconButton>  
            {auth && (
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>My account</MenuItem>
                </Menu>
              </div>
            )}
            <IconButton 
                aria-label="menu" 
                onClick={() => setOpenMenu(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer open={openMenu} anchor="right" onClose={() => setOpenMenu(false)}>
              <div className={classes.menu}>
                  <MenuItem className={classes.menuItem} onClick={() => OpenHomePage()}>Winkel</MenuItem>
                  <MenuItem className={classes.menuItem} onClick={() => OpenAboutPage()}>Over ons</MenuItem>
                  <MenuItem className={classes.menuItem} onClick={() => OpenDemoPage()}>Demo</MenuItem>
                  <MenuItem className={classes.menuItem} onClick={() => OpenContactPage()}>Contact</MenuItem>
              </div>
            </Drawer>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default PrimarySearchAppBar;
