import React from 'react';
import styled from "styled-components";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import background from "../../assets/bg8_2.png"
import backgroundHero from "../../assets/about-bg1.png"
import about2 from "../../assets/about2.png"
import about3 from "../../assets/about3.jpg"
import jesse from "../../assets/Jesse.jpg"
import nikki from "../../assets/Nikki.jpg"
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router-dom";
import logo from '../../assets/adventure.jpg';
import { Paper, List, ListItem, ListItemText, ListItemIcon, Link } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Mood from '@material-ui/icons/Mood';
import { Check } from '@material-ui/icons';
import Pricing from './pricing';

const Body = styled.div`
  width: 100%;
  background-image: ${(props) => (props.noBackground ? "" : `url("${background}")`)};
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
`;

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: 'var(--color-accent-purple)',
    minHeight: '250px',
    borderBottomLeftRadius: '30px',
    borderBottomRightRadius: '30px',
  },
  hero: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
   // backgroundImage: `url("${backgroundHero}")`,
    paddingTop: '8rem',
    paddingBottom: '8rem',
    color: 'var(--color-text-light)'
  },
  heroPaper: {    
    padding: '4rem',
    [theme.breakpoints.down('xs')]: {
      padding: '2.4rem',
    },
  },
  actionBox: {   
    display: 'flex', 
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },  
  heroImage: {
    padding: '2rem',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      display: 'flex',
      margin: 'auto',
      marginTop: '2rem',
    },
    [theme.breakpoints.down('xs')]: {
       display: 'none'
    },
  },
  section: {
    paddingTop: '6rem',
    paddingBottom: '6rem',
  }, 
  section2: {
    paddingTop: '6rem',
    paddingBottom: '6rem',
    backgroundColor: 'var(--color-background-white)'
  },
  sectionColumn: {
    paddingRight: '2rem',
    paddingLeft: '2rem'
  },
  sectionColumn1: {
    paddingRight: '2rem',
    paddingLeft: '2rem',
    '& > img': {
      float: 'right'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  sectionColumn2: {
    paddingRight: '2rem',
    paddingLeft: '2rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  image: {
    width: '100%'
  },
  image2: {
    width: '100%',
    borderRadius: '100%',
    maxWidth: '200px',
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
  },
  sectionImg: {
    minHeight: '400px',
    backgroundSize: 'cover',
    backgroundImage: `url("${about2}")`,
    backgroundRepeat: 'no-repeat',
   // backgroundAttachment: 'fixed',
    backgroundPosition: 'right',
    [theme.breakpoints.down('sm')]: {
      minHeight: '250px',
      backgroundPosition: 'center',
    //  backgroundAttachment: 'fixed',
    },
  },
  sectionBg: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
    backgroundColor: 'var(--color-background-ter)',
    backgroundSize: 'contain'
  },
  paper: {
    padding: '4rem',
    backgroundColor: 'var(--color-accent-prim)',
    color: 'var(--color-light-grey)',
    [theme.breakpoints.down('xs')]: {
       padding: '2.4rem'
    },
  },
  smiley:{
    '& > * > *':{
      fontSize: '60px',
      fill: 'var(--color-accent-prim)',
    }
  },
  icon:{
    color: 'var(--color-accent-prim)'
  },
  link: {
    color: 'var(--color-text-white)',
    fontWeight: '700',
    cursor: 'pointer',
    textDecoration: 'underline' 
  },
}));

export default function About(props) {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Body>
    <div className={classes.header}>
      <section className={classes.hero}>
        <Container maxWidth="lg">
          <div className={classes.heroPaper}>
            <Grid container spacing={3}>
              <Grid item sm={12} md={6}>
                <Box textAlign="left">
                  <h1 className={classes.title}>
                    Jouw cadeaubonnen op onze webshop?
                  </h1>
                  <Typography variant="subtitle1" color="inherit" paragraph={true} className={classes.description}>Start vandaag nog met het verkopen van jouw cadeaubonnen via onze webshop. Registreer je profiel gratis, voeg je cadeaubon toe en that's it!</Typography>
                  <Typography variant="subtitle1" color="inherit" paragraph={true} className={classes.description}>Daarna kan je via je eigen gepersonaliseerde dashboard je bonnen beheren, inwisselen en zo veel meer.</Typography>
                  <Typography variant="subtitle1" color="inherit" paragraph={true} className={classes.description}><a className={classes.link} href="https://stallesbon.blob.core.windows.net/public/Brochure.pdf" target="_blank">Bekijk hier onze brochure voor meer info!</a></Typography>
                  <Box mt={3} className={classes.actionBox}>
                    <Button style={{marginRight: '10px'}} variant="contained" className="customButton2" onClick={() => window.open("https://app.allesbon.be")}>REGISTREER</Button>
                    <Button variant="outlined" className="customButton3" onClick={() => history.push("/demo")}>DEMO</Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={12} md={6}>
                <img src={backgroundHero} alt="AllesBon" width="500px" className={classes.heroImage} /> 
              </Grid>
            </Grid>
          </div>
        </Container>
      </section>
    </div>
    <section className={classes.section}>      
      <Container maxWidth="md">
        <Grid container>
          <Grid item xs={12} sm={6} className={classes.sectionColumn}>
            <Box textAlign="left">
              <h1>
                Vergroot je online bereik
              </h1>
              <Typography variant="subtitle1" color="textSecondary" paragraph={true} className={classes.description}>Het online koopgedrag groeit elke dag en zal zeker nog exponentieel blijven groeien. Profiteer van een groter bereik door jouw winkel of onderneming op onze webshop te plaatsen en jouw cadeaubonnen eenvoudig vanaf daar te verkopen. Dit alles kan binnen de dag al opgestart worden! Waar wacht je nog op? </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.sectionColumn}>
            <img src={about3} alt="AllesBon" width="500px" className={classes.image} /> 
          </Grid>
        </Grid>
      </Container>
    </section>    
    <section className={classes.sectionImg}>   
    </section>
    <section className={classes.sectionBg}>      
      <Container maxWidth="md">
        <Grid container>
          <Grid item xs={12} sm={6} className={classes.sectionColumn}>
            <Box textAlign="left">
              <h1>
                Alles-in-1 dashboard
              </h1>
              <Typography variant="subtitle1" color="textSecondary" paragraph={true} className={classes.description}>Ons dashboard vereenvoudigdt administratie en vermijdt gebruikersfouten, onoverzichtelijke Excellijsten, complexe boekhouding, vergetelheden van personeel, etc. Het is super eenvoudig om cadeaubonnen op te zoeken, in te wisselen of een volledige historiek te raadplegen.</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.sectionColumn}>
            <Box textAlign="left">
              <h1>
                &nbsp;
              </h1>
              <Typography variant="subtitle1" color="textSecondary" paragraph={true} className={classes.description}>Deel correcte informatie eenvoudig met je boekhouder. Altijd een juiste up-to-date lijst in slechts enkele klikken. Rekenfouten worden eenvoudig uitgesloten: het systeem rekent zelf uit hoeveel er werd gebruikt van de bon, hoeveel er nog opstaat en hoeveel de klant eventueel nog moet opleggen. </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </section>     
    <section className={classes.section}>      
      <Container maxWidth="md">
        <Box textAlign="center">
          <h1>
            Tevreden klanten
          </h1>
          <Typography variant="subtitle1" color="textSecondary" paragraph={true} className={classes.description}>Klanten kunnen 24/7 cadeaubonnen aankopen en genieten van het gemak om deze digitaal ter beschikking te hebben. Geen onnodig afdruk meer en dat is voor iedereen fijn! Verder kunnen zij hun cadeaubon in verschillende keren opgebruiken en eventueel ook eenmalig verlengen.</Typography>
          <IconButton className={classes.smiley} >
            <Mood/>
          </IconButton>
        </Box>
      </Container>
    </section>  
    <section className={classes.section2}> 
      <Container maxWidth="md">
          <Box textAlign="center">
            <h1>
              Alle voordelen op een rijtje
            </h1>
              <List>
                <ListItem>
                  <ListItemIcon className={classes.icon}>
                    <Check />
                  </ListItemIcon>
                  <ListItemText primary="Ontmoet nieuwe klanten en bied je cadeaubonnen online aan."/>
                </ListItem>
                <ListItem>
                  <ListItemIcon className={classes.icon}>
                    <Check />
                  </ListItemIcon>
                  <ListItemText primary="Alles zelf in beheer: je kiest zelf hoeveel en voor welk bedrag je cadeaubonnen verkoopt."/>
                </ListItem>
                <ListItem>
                  <ListItemIcon className={classes.icon}>
                    <Check />
                  </ListItemIcon>
                  <ListItemText primary="Via de webshop kunnen klanten beveiligd afrekenen en ontvangen ze hun cadeaubon onmiddellijk via e-mail."/>
                </ListItem>
                <ListItem>
                  <ListItemIcon className={classes.icon}>
                    <Check />
                  </ListItemIcon>
                  <ListItemText primary="Het platform is volledig gratis, je betaald geen maandelijkse of andere kosten. Je betaald enkel €2,99 per verkochte bon. Niets verkocht = dus ook geen kosten!"/>
                </ListItem>
                <ListItem>
                  <ListItemIcon className={classes.icon}>
                    <Check />
                  </ListItemIcon>
                  <ListItemText primary="Spring mee op de jaarlijkse groei in verkoop van online cadeaubonnen."/>
                </ListItem>
                <ListItem>
                  <ListItemIcon className={classes.icon}>
                    <Check />
                  </ListItemIcon>
                  <ListItemText primary="Makkelijke koppeling met je boekhouding en geen fysieke bonnen meer die je 10 jaar moet archiveren."/>
                </ListItem>
                <ListItem>
                  <ListItemIcon className={classes.icon}>
                    <Check/>
                  </ListItemIcon>
                  <ListItemText primary="Maandelijks krijg je een detailoverzicht en worden alle verkochte cadeaubonnen doorgestort naar jou."/>
                </ListItem>
              </List>
          </Box>
      </Container> 
    </section>  
    <section className={classes.section}> 
      <Container maxWidth="md">
          <Box textAlign="center">
            <h1>
              Prijzen
            </h1>
            <Typography variant="subtitle1" color="textSecondary" paragraph={true} className={classes.description}>Betaal enkel een vast bedrag per cadeaubon. Volledig transparant en geen verrassende kosten!</Typography>
          </Box>
          <Pricing/>
          <Box textAlign="center">
            <Typography variant="subtitle1" color="textSecondary" paragraph={true} className={classes.description}>Elk moment opzegbaar 😉</Typography>
          </Box>
      </Container> 
    </section>
    <section className={classes.section}>      
      <Container maxWidth="md">
            <Box textAlign="center">
              <h1>
                Over ons
              </h1>
            </Box>
        <Grid container>
          <Grid item xs={12} sm={3} className={classes.sectionColumn}>
            <img src={jesse} alt="AllesBon" className={classes.image2} /> 
          </Grid>
          <Grid item xs={12} sm={9} className={classes.sectionColumn}>
            <Box textAlign="left">
              <h2>
                Jesse
              </h2>
              <Typography variant="subtitle1" color="textSecondary" paragraph={true} className={classes.description}>Als een creatieve web-developer met meer dan 8 jaar ervaring, combineer ik mijn technische kennis, creativiteit en passie voor het vak om het concept achter AllesBon om te zetten in een gebruiksvriendelijk platform.</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={3} className={classes.sectionColumn1}>
            <img src={nikki} alt="AllesBon" className={classes.image2} /> 
          </Grid>
          <Grid item xs={12} sm={9} className={classes.sectionColumn}>
            <Box textAlign="right">
              <h2>
                Nikki
              </h2>
              <Typography variant="subtitle1" color="textSecondary" paragraph={true} className={classes.description}>Uitvinder van het concept. In mijn jaren ondernemerschap ontdekte ik de nood aan een eenvoudig  online cadeaubon platform. Afgelopen maanden ontwikkelende ik samen met mijn partner hierop een duidelijk antwoord.</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.sectionColumn2}>
            <img src={nikki} alt="AllesBon" className={classes.image2} /> 
          </Grid>
        </Grid>
      </Container>
    </section>  
    <section className={classes.section}>      
      <Container maxWidth="md">
        <Paper className={classes.paper}>
          <Box textAlign="center">
            <h1>
            Start vandaag nog met de verkoop van jouw cadeaubon
            </h1>
            <Typography variant="subtitle1" paragraph={true} className={classes.description}>Meer weten over de mogelijkheden of op zoek naar een oplossing op maat? Wij staan voor je klaar.</Typography>
          </Box>
          <Box mt={3} textAlign="center">
            <Button style={{marginRight: '10px'}} className="customButton2" onClick={() => history.push("/contact")}>Contact</Button>
            <Button variant="outlined" className="customButton3" onClick={() => history.push("/demo")}>DEMO</Button>
          </Box>
        </Paper>
      </Container>
    </section> 
    </Body>
  );
}