import React from 'react';
import { Card, CardMedia, CardContent, CardActions, IconButton, Fade } from '@material-ui/core';
import { AddShoppingCart } from '@material-ui/icons';
import thumbnail from "../../../assets/no-thumbnail.png";

import useStyles from './styles';

const Product = ({ product, onAddToCart, idx }) => {
  const classes = useStyles();

  const handleAddToCart = () => onAddToCart(product, 1);

  return (
    <Fade in={true} style={{ transitionDelay: 100 * idx + 'ms'}}>    
      <Card className={classes.root}>
        <CardMedia className={classes.media} image={product.cover ?? thumbnail} title={product.name} />
        <CardContent>
          <div className={classes.cardContent}>
            <h3>
              Cadeaubon
            </h3>
            <h4>
              €{product.price}
            </h4>
          </div>
          <h5 className={classes.cardSubtitle}>{product.name}</h5>
          <i style={{marginTop: '1rem'}}>{product.description}</i>
        </CardContent>
        <CardActions disableSpacing className={classes.cardActions}>
          <IconButton aria-label="Add to Cart" onClick={handleAddToCart}>
            <AddShoppingCart />
          </IconButton>
        </CardActions>
      </Card>
    </Fade>
  );
};

export default Product;

