import React from 'react';
import styled from "styled-components";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { Card, CardMedia, CardContent, CardActions, IconButton, Fade, TableContainer, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { AddShoppingCart } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'var(--color-accent-sec)',
    color: 'var(--color-text-light)',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: 'var(--color-light-grey)',
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(3),
  },
  gridItem: {
    margin: 'auto',
  },
}));

export default function Pricing() {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Grid container alignItems="stretch" className={classes.grid}>
      <Grid key={1} item xs={12} sm={6} md={6} lg={6} className={classes.gridItem}>
        <Fade in={true} style={{ transitionDelay: '100ms'}}>  
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left"><b>Waarvoor</b></StyledTableCell>
                      <StyledTableCell align="right"><b>Bedrag</b></StyledTableCell>
                      <StyledTableCell align="right"><b>Per</b></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                      {/* <StyledTableRow>
                        <TableCell component="th" scope="row">Administratiekosten</TableCell>
                        <TableCell align="right">€ 20</TableCell>
                        <TableCell align="right">maand</TableCell>
                      </StyledTableRow> */}
                      <StyledTableRow>
                        <TableCell component="th" scope="row">Handelingskost</TableCell>
                        <TableCell align="right">€ 2,99</TableCell>
                        <TableCell align="right">bon</TableCell>
                      </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
        </Fade>
      </Grid>
    </Grid>
  );
}