import React from "react";
import { SupportForm } from "./Components/SupportForm";
import { useHistory } from "react-router-dom";
import Page from "../UI/Elements/Page";
import { Box } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //backgroundImage: `url(${background})`,
    backgroundSize: 'cover'
  },
  paper: {
    padding: theme.spacing(6),
    borderRadius: '30px',
    margin: 'auto',
    maxWidth: '80%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      padding: '3rem',
   },
  }
}));

const Contact = () => {
  const classes = useStyles();
  const history = useHistory();

  const theme = {
    spacing: 8,
  }

  return (
    <div className={classes.root}>
      <Page>
        <Paper className={classes.paper}>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <h1>Opmerking, vraag of suggestie?</h1> 
              <p>Neem gerust contact met ons op via het formulier.</p>
              <Box my={4}>
                <h3>Meteen weten hoe AllesBon werkt?</h3>    
                <Button style={{marginTop: '1rem'}}variant="outlined" className="customButton2" onClick={() => history.push("/demo")}>DEMO</Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <SupportForm onClose={() => history.go(0)}/>
            </Grid>
          </Grid>
        </Paper>
      </Page>
    </div>
  );
};

export default Contact;
