import { getAxiosClient } from "../axios";

export const getOrganization = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/organizations/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getOrganizations = async (params) => {
  const axiosClient = await getAxiosClient();
  return await axiosClient
    .get(`/organizations`, { params: {...params}} )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getCategories = async () => {
  const axiosClient = await getAxiosClient();
  return await axiosClient
    .get(`/categories`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};


export const getCountries = async () => {
  const axiosClient = await getAxiosClient();
  return await axiosClient
    .get(`/countries`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const createLead = async (name) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/lead/${name}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};