import { getAxiosClient } from "../axios";

export const addOrder = async (order) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/orders`, order)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const getOrder = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/orders/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const createPayment = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/orders/${id}/payment`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};

export const checkVat = async (vat) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/vat/${vat}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
};