import React from 'react';
import { Card, CardMedia, CardContent, CardActions, IconButton, Fade } from '@material-ui/core';
import { Category, Euro, Language, LocationOn } from '@material-ui/icons';
import thumbnail from "../../../assets/no-thumbnail.jpg";

import useStyles from './styles';
import { Link } from 'react-router-dom';

const Organization = ({ organization, idx }) => {
  const classes = useStyles();

  const openWebsiteLink = (url) => {
    if (!url.startsWith("http")){
      url = "https://" + url;
    }
    window.open(url, '_blank', 'noreferrer');
  }
  
  const openLocationLink = (address, postalCode, city) => {
    let url = "https://maps.google.com/?q=" + address.replace(/\s/g, '') + "+" + postalCode + "+" + city;
    window.open(url, '_blank', 'noreferrer');
  }  

  return (
    <Fade in={true} style={{ transitionDelay: 100 * idx + 'ms'}}>    
      <Link to={`/products/${organization.slug ?? organization.id}`}>
        <Card className={classes.root}> 
          <CardMedia className={classes.media} image={organization.logoUrl ?? thumbnail} title={organization.name} />
          <CardContent>
            <div className={classes.cardContent}>
              <h3>
                {organization.name}
              </h3>
              {/* <h4>
                {organization.price}
              </h4> */}
            </div>
            <IconButton className={classes.iconButton} size="small">
              <Category/> 
              {organization.categoryName}
            </IconButton>
            <IconButton className={classes.iconButton} size="small">
              <LocationOn/> 
              <span>{organization.city} ({organization.postalCode})</span>
            </IconButton>
            <IconButton className={classes.iconButton} size="small">
              <Euro/> 
              {organization.prices}
            </IconButton>
          </CardContent>
        </Card>
      </Link>
    </Fade>
  );
};

export default Organization;

