import React from 'react';
import { Container, Typography, Button, IconButton } from '@material-ui/core';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from 'react-router-dom';
import useStyles from './styles';
import Loader from "../UI/Loader";
import { Delete } from '@material-ui/icons';
import { getTotalPrice, getLineTotalPrice } from '../../global/datahelper';
import Page from '../UI/Elements/Page';

const Cart = ({ cartItems, onUpdateCartQty, onRemoveFromCart, onEmptyCart }) => {
  const classes = useStyles();

  const handleEmptyCart = () => onEmptyCart();

  const handleUpdateCartQty = (lineItemId, newQuantity) => onUpdateCartQty(lineItemId, newQuantity);
  const handleRemoveFromCart = (lineItemId) => onRemoveFromCart(lineItemId);

  const renderEmptyCart = () => (
    <Typography variant="subtitle1">Uw winkelwagen is leeg,
      <Link className={classes.link} to="/"> voeg een item toe.</Link>
    </Typography>
  );

  function createData(number, item, qty, price) {
    return { number, item, qty, price };
   }     

  if (!cartItems) return <Loader center />;

  const renderCart = () => (
    <>
     <TableContainer component={Paper}>
     <Table aria-label="simple table">
       <TableHead>
         <TableRow>
           <TableCell>Product</TableCell>
           <TableCell align="center">Aantal</TableCell>
           <TableCell align="right">Totaal prijs&nbsp;(€)</TableCell>
           <TableCell align="right"></TableCell>
         </TableRow>
       </TableHead>
       
       <TableBody>
         {cartItems.map((item) => (
           <TableRow key={item.id}>
             <TableCell component="th" scope="row">{item.organization}: {item.productName}</TableCell>
             <TableCell align="center">
                <Button type="button" size="small" onClick={() => handleUpdateCartQty(item.productId, item.quantity - 1)}>-</Button>
                &nbsp;{item.quantity}&nbsp;
                <Button type="button" size="small" onClick={() => handleUpdateCartQty(item.productId, item.quantity + 1)}>+</Button>
             </TableCell>
             <TableCell align="right">{getLineTotalPrice(item)}</TableCell>
             <TableCell align="right">              
              <IconButton aria-label="Add to Cart" onClick={() => handleRemoveFromCart(item.productId)}>
                <Delete />
              </IconButton>
             </TableCell>
           </TableRow>
         ))}
       </TableBody>
        <TableRow className={classes.finalRow}>
          <TableCell align="right" colSpan={3}>
             <b>Totaal: </b> €{getTotalPrice(cartItems)}
          </TableCell>
        </TableRow>
     </Table>
   </TableContainer>

      <div className={classes.cardDetails}>
        <h3/>
        <div>
          {/* <Button className={classes.emptyButton} size="large" type="button" variant="contained" color="secondary" onClick={handleEmptyCart}>Empty cart</Button> */}
          <Button className="customButton" component={Link} to="/checkout" size="large" type="button" >Afrekenen</Button>
        </div>
      </div>
    </>
  );

  return (
    <Page noBackground>
      <Typography className={classes.title} gutterBottom>
        <h1>Uw winkelwagen</h1>
      </Typography>
      { !cartItems.length ? renderEmptyCart() : renderCart() }
    </Page>
  );
};

export default Cart;
