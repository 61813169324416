import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Facebook, Instagram } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  section: {
    minHeight: '250px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    //backgroundImage: `url("${background}")`,
    backgroundPosition: 'bottom',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    alignItems: 'center',
    color: 'var(--color-text)',
  },
  socials:{
    marginBottom: '1rem',
  },
  socialIcon:{
    '& > * > *':{
      fontSize: '2.4rem',
      fill: 'var(--color-accent-purple)',
    }
  },
  link:{    
    color: 'var(--color-text)',
    textDecoration: 'none'
  },
  container:{
    textAlign: 'center',
    fontSize: '.8rem',
    backgroundColor: 'var(--color-accent-pink)',
    padding: '1rem',
    borderTopLeftRadius: '30px',
    borderTopRightRadius: '30px',
  },
  privacy:{
    display: 'flex',
    fontSize: '.6rem',
    '& > a':{
     margin: '.8rem'
    }
  }
}));


const Footer = () => {
  const classes = useStyles();
 
  return (
    <section className={classes.section}>   
      <h2>Volg ons!</h2> 
      <div className={classes.socials}>
         <IconButton className={classes.socialIcon} onClick={() => window.open('https://www.instagram.com/allesbon.be/')} aria-label="Instagram" color="inherit">
            <Instagram />
         </IconButton> 
         <IconButton className={classes.socialIcon} onClick={() => window.open('https://www.facebook.com/profile.php?id=100092004995731')} aria-label="Facebook" color="inherit">
            <Facebook />
         </IconButton> 
      </div>
      <div className={classes.container}>
      <a href="https://www.corbital.be/" target="_blank" className={classes.link}>&copy; 2023 www.corbital.be</a>

      <div className={classes.privacy}>
          <a href="https://stallesbon.blob.core.windows.net/public/TERMSANDCONDITIONS.pdf" target="_blank" className={classes.link}>Terms and Conditions</a>
          <a href="https://stallesbon.blob.core.windows.net/public/PRIVACYPOLICY.pdf" target="_blank" className={classes.link}>Privacy policy</a>
          <a href="https://stallesbon.blob.core.windows.net/public/RETURNPOLICY.pdf" target="_blank" className={classes.link}>Return policy</a>
      </div>
      </div>
    </section>
  );
};

export default Footer;
