import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import { Skeleton } from "@material-ui/lab";
import Product from "../Products/Product/Product";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Loader from "../UI/Loader";
import Pagination from "../Pagination";
import { getOrganizationProducts } from "../../api/services/product";
import { Euro, Language, LocationOn, Category } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { UseErrorContext } from "../../global/contexts";
import { getOrganization } from "../../api/services/organization";
import { useParams } from "react-router-dom";
import { Typography, IconButton } from "@material-ui/core";
import useStyles from "./styles";
import Page from "../UI/Elements/Page";
import flowers from "../../assets/flowers.png";

const OrganizationProducts = ({ onAddToCart }) => {
  const { organizationSlug } = useParams();
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const [filterPrice, setFilterPrice] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const { updateError } = UseErrorContext();
  const [organization, setOrganization] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const refresh = async () => {
      try {
        if (!organization) return;

        setLoading(true);

        let params = {
          organization: organization,
          price: filterPrice,
          status: "Actief",
          hideVariables: true,
        };
        const data = await getOrganizationProducts(organization.id, params);
        setProducts(data);

        setLoading(false);
      } catch (err) {
        updateError(err);
      }
    };

    refresh();
  }, [currentPage, filterPrice]);

  useEffect(() => {
    const getOrganizationData = async () => {
      try {
        setLoading(true);

        const data = await getOrganization(organizationSlug);

        setOrganization(data);

        let params = {
          price: filterPrice,
          status: "Actief",
          hideVariables: true,
        };
        const orgProducts = await getOrganizationProducts(data.id, params);
        setProducts(orgProducts);

        setLoading(false);
      } catch (err) {
        updateError(err);
      }
    };
    getOrganizationData();
  }, []);

  const onChangeFilterPrice = (price) => {
    if (filterPrice !== price) {
      setFilterPrice(price);
    } else {
      setFilterPrice(null);
    }
  };

  const openWebsiteLink = (url) => {
    if (!url.startsWith("http")) {
      url = "https://" + url;
    }
    window.open(url, "_blank", "noreferrer");
  };

  const openLocationLink = (address, postalCode, city) => {
    let url =
      "https://maps.google.com/?q=" +
      address.replace(/\s/g, "") +
      "+" +
      postalCode +
      "+" +
      city;
    window.open(url, "_blank", "noreferrer");
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowAlert(false);
  };

  const _onAddToCart = (product, quantity) => {
    setShowAlert(true);
    onAddToCart(product, quantity);
  };

  const getBackgroundImage = (organization) => {
    if (organization.name == "BloemBar") {
      return flowers;
    }
    if (organization.name == "test") {
      // todo
    }
  };

  if (loading) return <Loader center />;

  return (
    <>
      <div className={classes.header}>
        {organization && (
          <div
            className={classes.topbar2}
            style={{
              backgroundImage: `url(${getBackgroundImage(organization)})`,
            }}
          >
            <Card className={classes.root2}>
              <div className={classes.cardBody}>
                <CardMedia
                  className={classes.cover2}
                  image={organization.logoUrl}
                  title={organization.name}
                />
                <div>
                  <CardContent className={classes.content2}>
                    <h2>{organization.name}</h2>
                    <IconButton size="small">
                      <Category />
                      {organization.categoryName}
                    </IconButton>
                    {organization.websiteURL && (
                      <IconButton
                        size="small"
                        onClick={() => openWebsiteLink(organization.websiteURL)}
                      >
                        <Language />
                        {organization.websiteURL}
                      </IconButton>
                    )}
                    <IconButton
                      size="small"
                      onClick={() =>
                        openLocationLink(
                          organization.addressLine,
                          organization.postalCode,
                          organization.city
                        )
                      }
                    >
                      <LocationOn />
                      {organization.addressLine}, {organization.postalCode}{" "}
                      {organization.city}
                    </IconButton>
                  </CardContent>
                </div>
              </div>
              <div className={classes.cardFooter}>
                <p>{organization.description}</p>
              </div>
            </Card>
          </div>
        )}
      </div>
      <Page noBackground noHeader>
        {/* <div className={classes.filters}>
        <Button variant="contained" className={classes.filterButton + `${filterPrice == "50" ? ' ' + classes.active : ''} `} startIcon={<Euro/>} onClick={() => onChangeFilterPrice(50)}>Onder de 50</Button>
      </div>   */}
        <div className={classes.content}>
          <div className={classes.products}>
            <Grid
              container
              alignItems="stretch"
              className={classes.productGrid}
            >
              {products.length == 0 && <p>Geen cadeaubonnen beschikbaar</p>}
              {products?.map((product, idx) => (
                <Grid key={product.id} item xs={12} sm={6} md={4} lg={3}>
                  {product ? (
                    <Product
                      product={product}
                      onAddToCart={_onAddToCart}
                      idx={idx}
                    />
                  ) : (
                    <Skeleton variant="rect" width={210} height={118} />
                  )}
                </Grid>
              ))}
            </Grid>
            <Pagination
              numberOfPages={numberOfPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </Page>
      <Snackbar
        open={showAlert}
        autoHideDuration={2500}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity="success">
          Toegevoegd aan winkelwagen.
        </Alert>
      </Snackbar>
    </>
  );
};

export default OrganizationProducts;
