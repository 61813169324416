import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Page from "../UI/Elements/Page";
import { createPayment, getOrder } from "../../api/services/order";
import { Button } from "@material-ui/core";
import { Typography, List, ListItem, ListItemText } from '@material-ui/core';
import Loader from '../UI/Loader';
import { UseErrorContext } from '../../global/contexts';
import { getTransactionPrice } from '../../global/datahelper';

const Pay = () => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const { updateError } = UseErrorContext();

  useEffect(() => {
    const refresh = async () => {
      try {
        setLoading(true);
        const data = await getOrder(id);
        console.log("data", data)
        setOrder(data);
        setLoading(false);
      } catch (err) {
        updateError(err);
      }
    };

    refresh();
  }, []);

  const payOrder = async () => {
    try {
      setLoading(true);
      const res = await createPayment(id);
      console.log("payment", res)
      window.location.href = res.sessionUrl;
      setLoading(false);
    } catch (err) {
      updateError(err);
    }
  }

  return (
    <Page noBackground>
      <div className="page__header">
        <h1>Betalen</h1>
      </div>
      <div className="page__inner">
      { loading || !order
        ? <Loader center />
        : <div>
            <h2>Order: {order.id}</h2>
            <List disablePadding>
              {order.orderLines.map((item, idx) => (
                <ListItem style={{ padding: '10px 0' }} key={idx}>
                  <ListItemText primary={`${item.product.organization.name} ${item.product.name}`} secondary={`Aantal: ${item.quantity}`} />
                  <span>€{item.totalPrice}</span>
                </ListItem>
              ))}
              <ListItem style={{ padding: '10px 0' }}>
                <ListItemText primary="Totaal" />
                <h3 style={{ fontWeight: 700 }}>
                  €{order.total}
                </h3>
              </ListItem>
            </List>
            <br/><p className="small-text"><i>Transactiekosten (€{getTransactionPrice(order.orderLines)})</i></p><br/>
            { loading 
              ? <Loader center />
              : <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  { order.total > 0.50
                  ? <Button onClick={() => payOrder()} className="customButton paymentButton">
                      {/* Betaal €{total} */}
                      Betaal
                    </Button>
                  : <p>Bedrag moet groter zijn dan €0,50</p>
                  }
                </div>
            }

          </div>
        }
      </div>
    </Page>
  );
};

export default Pay;
