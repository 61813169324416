import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { SendDemoMessage } from "../../../api/services/support";
import { UseErrorContext } from "../../../global/contexts";
import { useForm, FormProvider } from 'react-hook-form';
import FormInput from "../../UI/Elements/CustomTextField";
import { Button, Grid } from '@material-ui/core';
import Loader from '../../UI/Loader';
import { ValidateEmail } from '../../../global/datahelper';

export const DemoForm = () => {
  const methods = useForm();
  const { updateError } = UseErrorContext();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const callBack = async (values) => {
    try{
      setLoading(true);
      if (ValidateEmail(values.email)){
        updateError({"message": "Ongeldig e-mailadres."});
      } 
      else{
        const resp = await SendDemoMessage(values);
        if (resp){        
          history.push("/support-success")
        }
        return resp;
      }
    } catch (err) {
      updateError(err);
      return Promise.reject(err);
    }
    finally{
      setLoading(false);
    }
  };

  return (
    <>
      <FormProvider {...methods}>   
         <form onSubmit={methods.handleSubmit((data) => {console.log(data); callBack(data);})}>
         <Grid container spacing={3}>
            <FormInput required name="firstName" label="Voornaam" />
            <FormInput required name="lastName" label="Achternaam" />
            <FormInput required name="email" label="E-mailadres" fullWidth/>
            <FormInput required name="phone" label="Telefoonnummer" fullWidth/>
            <FormInput name="message" label="Opmerking" fullWidth multiline/>
          </Grid>
          <br />
          {loading
          ? <Loader center />
          : <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button type="submit" className="customButton">Verstuur</Button>
            </div>
          }
        </form>
      </FormProvider>   
    </> 
  );
};
