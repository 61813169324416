import React, { useState, useEffect } from 'react';
import Page from "../UI/Elements/Page";

  const Success = () => {
    useEffect(() => {
      const resetCart = async () => {
        localStorage.setItem('items', JSON.stringify([]))
        localStorage.setItem('messages', JSON.stringify([]))
        localStorage.setItem('customer', JSON.stringify({}))
      };

      resetCart();
  }, []);

  return (
    <Page noBackground>
      <div className="page__header">
        <h1>Bedankt voor uw aankoop</h1>
      </div>
      <div className="page__inner">
        <div>
          <p>U krijgt zo dadelijk een e-mail met uw orderbevestiging en aangekochte cadeaubon(en).</p>
          <p><a href="/">Winkel verder</a></p>
        </div>
      </div>
    </Page>
  );
};

export default Success;
