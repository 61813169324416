import React, { useState, useEffect } from 'react';
import { CssBaseline, Paper, Stepper, Step, StepLabel, Typography } from '@material-ui/core';
import PaymentForm from '../PaymentForm';
import MessageForm from '../MessageForm';
import useStyles from './styles';
import InvoiceForm from '../InvoiceForm';
import Page from '../../UI/Elements/Page';

const steps = ['Uw bericht', 'Gegevens', 'Betalen'];

const Checkout = ({ cartItems }) => {
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();

  const nextStep = () => {setActiveStep((prevActiveStep) => prevActiveStep + 1)};
  const backStep = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const Form = () => (
    activeStep === 0
    ? <MessageForm nextStep={nextStep}/>
    : activeStep === 1
      ? <InvoiceForm backStep={backStep} nextStep={nextStep}/>
      : <PaymentForm nextStep={nextStep} backStep={backStep} /> 
    );

  return (
    <Page noBackground>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography align="center">
            <h1>Bestellen</h1>
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {cartItems.length > 0 && <Form />}
        </Paper>
      </main>
    </Page>
  );
};

export default Checkout;
