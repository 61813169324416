import React from 'react';
import { Typography, List, ListItem, ListItemText } from '@material-ui/core';
import { getTotalPrice, getLineTotalPrice, getTransactionPrice } from '../../global/datahelper';

const Review = ({ cartItems }) => (
  <>
    <h2>Besteloverzicht</h2>
    <List disablePadding>
      {cartItems.map((item, idx) => (
        <ListItem style={{ padding: '10px 0' }} key={idx}>
          <ListItemText primary={`${item.organization} ${item.productName}`} secondary={`Aantal: ${item.quantity}`} />
          <span>€{getLineTotalPrice(item)}</span>
        </ListItem>
      ))}
      <ListItem style={{ padding: '10px 0' }}>
        <ListItemText primary="Totaal" />
        <h3 style={{ fontWeight: 700 }}>
          €{getTotalPrice(cartItems)}
        </h3>
      </ListItem>
    </List>
  </>
);

export default Review;
