import { createGlobalStyle } from "styled-components";
import reset from "reset-css";
import background from '../../assets/bg7.png'


// Font awesome icons at:
// https://react-icons.github.io/react-icons/icons?name=fa

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopM: "1200px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopM: `(min-width: ${size.laptopM})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export default createGlobalStyle`
  ${reset}

  
html {
    --color-background-white: #ffffff;
    --color-background-grey: #f5f5f5;
    --color-accent-prim: #D094D8; // purple/pink
    --color-accent-sec: #F0A98B; // orange
    --color-accent-tir: #8b55d4; // dark purple
    --color-accent-purple: #D094D8;
    --color-accent-orange: #F0A98B;
    --color-accent-pink: #FCF5F2;
    --color-text: #000000;
    --color-text-medium: #2B124C;
    --color-text-light: #ffffff;
    --color-white: #ffffff;
    --color-black: #000000;
    --color-light-grey: #f2f2f2;
    --color-medium-grey: #e5e5e5;
    --color-dark-grey: #a7a7a7;
    --color-status-new: #3A3A3A;
    --color-status-good: #1bb775;
    --color-status-bad: #d62809;
    --color-status-warning: #d98a02;
}

:root {
    position: relative;
    min-height: calc(100vh - 64px);
    width:100&;
    margin:auto;
    color: var(--color-text);
    font-family: 'Montserrat', sans-serif;}

html, *{box-sizing: border-box;}
*,*:before,*:after {box-sizing: inherit;}
::-webkit-scrollbar {
    /*display: none;*/
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: lightgray;
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

body{    
  background-size: contain;
  background-repeat: no-repeat;
 // background-image: url(${background});
  background-color: var(--color-background-grey);
  background-position: bottom;
  color: var(--color-text);
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  font-size: 1rem;
  overflow-x: hidden;
 // min-height: calc(100vh - 64px);
}

/*-------main_style-------*/
a {
  text-decoration: none;
  :active{
    color: var(--color-accent-prim);
  }
}

/*-------Headers-------*/
h1 {
  font-weight: 500;
  font-size: 3rem;
  text-transform: uppercase;
  font-family: 'Barlow Condensed', sans-serif;
}

h2 {
  font-weight: 500;
  font-size: 2rem;
  font-family: 'Barlow Condensed', sans-serif;
  a {    
    font-size: 1.4rem;
  }
}
h3 {
  font-weight: 500;
  font-size: 1.4rem;
  font-family: 'Barlow Condensed', sans-serif;
}
h4 {
  font-weight: 500;
  font-size: 1.2rem;
  font-family: 'Barlow Condensed', sans-serif;
}

i{
  font-style: italic;
}

/*-------MUI-------*/
.MuiBadge-colorSecondary {
  color: #fff;
  background-color: var(--color-accent-tir);
}
.MuiStepIcon-root.MuiStepIcon-active {
  color: var(--color-accent-prim);
}
.MuiStepIcon-root.MuiStepIcon-completed {
  color: var(--color-accent-sec);
}
.MuiCardContent-root {
    min-height: 6rem;
}
.MuiInput-underline:after {
    border-bottom: 2px solid var(--color-accent-prim);
}
.MuiFormLabel-root.Mui-focused {
  color: var(--color-accent-prim);
}
.MuiSlider-root {
  color: var(--color-accent-prim);
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--color-accent-prim);
}
.MuiCheckbox-colorSecondary:hover, .MuiCheckbox-colorSecondary.Mui-checked:hover {
  background-color: rgba(255,255,255 ,0.5);
}
.MuiButton-contained{
  background-color: var(--color-medium-grey);
  :hover{    
    background-color: var(--color-dark-grey);
  }
}

.MuiIconButton-root{
  color: inherit;
}

.MuiIconButton-label{
  text-align: left;
}

.MuiButton-root:hover {
  text-decoration: none;
  background-color: initial;
}

/*-------CUSTOM-------*/
.required:after {
    content:" *";
    color: var(--color-accent-prim);
}

.customButton {
  font-weight: 700;
  border: none;
  border-radius: 30px;
  min-width: 120px;
  color: var(--color-white);
  margin-right: 1rem;
  background-color: var(--color-accent-purple);
  :hover{
    background-color: var(--color-accent-purple);
    opacity: 70%;
  }
}

.paymentButton {
  font-size: 16px;
}

.customButton2 {
  border: none;
  border-radius: 30px;
  min-width: 120px;
  color: var(--color-text);
  background-color: var(--color-accent-pink);
  border: none;
  margin-right: 1rem;
  :hover{
    background-color: var(--color-accent-pink);
    opacity: 70%;
  }
}

.customButton3 {
  min-width: 120px;
  border-color: var(--color-white);
  color: var(--color-white);
  margin-right: 1rem;
  border-radius: 30px;
  :hover{
    opacity: 70%;
  }
}

.small-text{
  font-size: .8rem;
}

.colorText{
  color: var(--color-text-medium);
}
`;
