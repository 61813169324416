import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    display: 'flex',
  },
  products: {
    width: '100%'
  },
  root: {
    flexGrow: 1,
  },
  productGrid: {
    padding: theme.spacing(3),
  },
  topbar: {
    marginTop: '84px',
    backgroundSize: 'cover',
    backgroundColor: 'var(--color-light-grey)',
    boxShadow: '2px 5px 12px -9px black',
    marginBottom: '2rem',
    height: '180px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'start',
    },
  },
  categoryCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',    
    fontSize: '.8rem',
    cursor: 'pointer',
    transition: 'all .2s ease-in-out' ,
    '&:hover > * > *': {
      transform: 'scale(1.02)',      
      filter: 'grayscale(0%)',
    },
  },  
  categoryImgBox: {
    borderRadius: '100px',
    padding: '4px',
    width: '100px',
    height: '100px',
    margin: '12px',
    border: '2px solid',
    borderColor: 'var(--color-accent-prim)',
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  },
  categoryImg: {
    borderRadius: '100px',
    width: '100%',
    height: '100%',
    filter: 'grayscale(100%)',
  },
  active: {
    color: 'var(--color-accent-prim)',
    fontWeight: '700',    
    '& > * > *': {
      transform: 'scale(1.02)',      
      filter: 'grayscale(0%)',
    },
  },
  filters: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
}));
