import React from "react";
import Page from "../UI/Elements/Page";

const SupportSuccess = () => {

  return (
    <Page noBackground>
      <div className="page__header">
        <h1>Verzonden</h1>
      </div>
      <div className="page__inner">
        <div>
          <p>Uw bericht is succesvol verzonden! WIj nemen zo spoedig mogelijk contact met u op.</p>
          <p>Ga terug naar <a href="/">home.</a></p>
        </div>
      </div>
    </Page>
  );
};

export default SupportSuccess;
