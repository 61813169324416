import React from "react";
import Page from "../UI/Elements/Page";

const Cancel = () => {

  return (
    <Page noBackground>
      <div className="page__header">
        <h1>Mislukt</h1>
      </div>
      <div className="page__inner">
        <div>
          <p>Er is iets fout gelopen bij de betaling, gelieve opnieuw te proberen.</p>
          <p>Ga terug naar <a href="/checkout">betaling.</a></p>
        </div>
      </div>
    </Page>
  );
};

export default Cancel;
