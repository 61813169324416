import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from '@material-ui/core';
import { UseErrorContext } from "../global/contexts";

const Div = styled.div`  
  z-index: 999;
  position: fixed;
	width: 100%;
	height: 100%;
	display: grid;
	justify-items: center;
	align-items: center;
  background: rgba(0, 0, 0, 0.7);
  top: 0;

  .error_modal{    
    width: 600px;

    &__header {
      background-color: var(--color-accent-prim);
      padding: 16px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      color: var(--color-white);
    } 
    &__body{
      background-color: var(--color-white);
      padding: 16px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      overflow: auto;
      box-shadow: 5px 6px 12px -5px black;
      color: var(--color-black);
    } 
  }    
  .modal-buttons{
    margin-top: 1rem;
  }
`;

const Error = () => {
  const { errorMessage, updateError } = UseErrorContext();

  const onClose = () => {
    updateError("");
  }

  return (
    <>
      { errorMessage &&
      <Div>   
          <div className="error_modal">
            <div className="error_modal__header">
              <p>{errorMessage?.response?.data?.status} ERROR</p>
            </div>
            <div className="error_modal__body">
              <p>{errorMessage?.response?.data?.title ?? errorMessage?.message ?? ""}</p>         
              <div className="modal-buttons">
                {/* TODO style me? */}
                <Button onClick={() => onClose()} size="large" type="button" variant="contained">Sluiten</Button>
              </div>
            </div>   
          </div>        
      </Div>
      }
    </>
  );
};

export default Error;
