import { makeStyles } from '@material-ui/core/styles';
import flowers from "../../assets/flowers.png";
import kerst3 from "../../assets/kerst3.png"

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  header: {
    backgroundColor: 'var(--color-accent-purple)',
    minHeight: '250px',
    borderBottomLeftRadius: '30px',
    borderBottomRightRadius: '30px',
  },
  content: {
    //flexGrow: 1,
    //display: 'flex',
    //minHeight: '300px',
    padding: '1rem',
    backgroundColor: 'var(--color-background-white)',
    borderRadius: '30px',

    // KERST
    // backgroundImage: `url("${kerst3}")`,
    // backgroundPosition: 'top',
    // backgroundSize: 'contain',
    // backgroundRepeat: 'no-repeat'
  },
  items: {
    width: '100%'
  },
  root: {
    flexGrow: 1,
  },
  info: {
    padding: '2rem 2rem',
    backgroundColor: 'var(--color-background-white)',
    borderRadius: '30px',
    textAlign: 'center',
    '& > button > .MuiIconButton-label':{
      textAlign: 'center',
    },
    '& > p':{
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
    }
  },
  topbar: {
    padding: '2rem 0 2rem 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
    flexWrap: 'wrap',
  },
  topbar2: {
    //backgroundImage: `url(${flowers})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    //height: '550px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '95%',
    margin: 'auto',
    paddingTop: '8rem',
    [theme.breakpoints.down('sm')]: {
      //height: '600px',
      paddingTop: '4rem'
    },
  },
  categoryCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',    
    fontSize: '.8rem',
    cursor: 'pointer',
    transition: 'all .2s ease-in-out' ,
    '&:hover > * > *': {
      transform: 'scale(1.02)',      
      filter: 'grayscale(0%)',
    },
  },  
  categoryImgBox: {
    borderRadius: '100px',
    padding: '4px',
    width: '100px',
    height: '100px',
    margin: '12px',
    border: '2px solid',
    borderColor: 'var(--color-accent-prim)',
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  
  },
  categoryImg: {
    borderRadius: '100px',
    width: '100%',
    height: '100%',
    filter: 'grayscale(50%)',
  },
  active: {
    color: 'var(--color-accent-prim)',
    fontWeight: '700',  
    '& > *': {
      borderColor: 'var(--color-accent-prim)',
    },  
    '& > * > *': {
      transform: 'scale(1.02)',      
      filter: 'grayscale(0%)',
    },
  },
  filters: {
    paddingBottom: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      marginTop: '.5rem',
    },
  },
  root2: {
    padding: '1rem',
    borderRadius: '30px',
    margin: '2rem',
    maxWidth: '80%',
    backgroundColor: 'var(--color-white)',
    color: 'var(--color-text)',      
    '& > *': {
      color: 'var(--color-text)',
    },
  },
  cardBody: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  content2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    overflowWrap: 'anywhere',
  },
  cover2: {
    width: 150,
    height: 150,
    borderRadius: '100%',
    backgroundSize: 'contain'
  },
  cardFooter: {
    marginTop: '1rem',
  },
  // KERST
  kerst:{
    // backgroundImage: `url("${kerst3}")`,
    // backgroundPosition: 'top',
    // backgroundSize: 'contain',
    // backgroundRepeat: 'no-repeat'
  },
  iconButton: {
    backgroundColor: 'var(--color-accent-purple)'
  },
  iconButton2: {
    backgroundColor: 'var(--color-accent-pink)',
    fontSize: '1rem'
  },
  invite:{
    marginTop: '1rem',
    textAlign: 'center'
  }
}));
