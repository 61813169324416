import { makeStyles, fade } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    // maxWidth: 345, original width style
    maxWidth: '100%',
    cursor: 'pointer',
   // outline: 'solid 0px var(--color-accent-sec)',
    transition: 'all .2s ease-in-out' ,
    margin: '0.5em',
    backgroundColor: 'var(--color-white)',
    border: 'none',
    borderRadius: '30px',
    '&:hover': {
      transform: 'scale(1.02)',
      opacity: '70%'
    },
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    backgroundSize: 'contain',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconButton: {
    textAlign: 'left',
    display: 'flex',
    '& > * > *': {
      marginRight: '1rem',
    },
    '&:hover': {
      backgroundColor: 'white'
    },
  }
}));
