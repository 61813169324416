import React, { useState, useEffect } from 'react';
import { Button, Typography, Divider} from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import useStyles from './styles';
import MessageBox from './MessageBox';

const MessageForm = ({ nextStep}) => {
  const methods = useForm();
  const classes = useStyles();
  const [messageData, setMessageData] = useState([]);
  const [showWarning, setShowWarning] = useState(true);

  useEffect(() => {
    const refresh = async () => {
      let data = JSON.parse(localStorage.getItem('messages')) ?? [];

      if (data.length === 0){
        let cartItems = JSON.parse(localStorage.getItem('items')) ?? [];

        cartItems.map(item => {
          for (let i = 0; i < item.quantity; i++) {
            let res = {
              "productId": item.productId,
              "productName": item.productName,
              "organization": item.organization,
              "price": item.price,
              "from": "", 
              "to": "",
              "message": ""
            };
            data.push(res);
          }
        })
      }
      setMessageData(data);
    };
    refresh();
  }, []);

  const callBack = async (id, m) => {
    let messages = messageData;
    messages[id] = {...m};
    setMessageData(messages);
    updateWarning(messages);
  };

  const setMessageCache = async (data) => {
    localStorage.setItem('messages', JSON.stringify(data));
  };

  const updateWarning = (messages) => {
    let result = false;
    for (const message in messages) {
      let m = messages[message];
      if (!m.from || !m.to || !m.message){
        result = true;
      }
    }
    setShowWarning(result);      
  };

  return (
    <>
      <h2>Persoonlijk bericht</h2>   
      <FormProvider {...methods}>   
         <form onSubmit={methods.handleSubmit((data) => {console.log(messageData); setMessageCache(messageData); nextStep();})}>
          {messageData.map((message, idx) => (
            <div key={idx}>
              <MessageBox id={idx} {...message} callBackMessageData={callBack}/>             
              <br />        
              <Divider className={classes.divider} />
              <br />        
            </div>
          ))}
          <br />
          { showWarning && 
          <div>
            <i><b>LET OP</b> niet alle persoonlijke berichten zijn ingevuld, dit is de enige stap waar u dit kan doen.</i>
            <br /><br />
          </div>}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button component={Link} variant="outlined" to="/cart">Terug</Button>
            <Button type="submit" className="customButton">Verder</Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default MessageForm;
