import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Skeleton } from '@material-ui/lab';
import Product from './Organization/Organization';
import useStyles from './styles';
import Loader from "../UI/Loader";
import Pagination from "../Pagination";
import { Euro, Fastfood, FilterHdr, KingBed, ShoppingBasket, Star, LocationOn, FlashOn } from '@material-ui/icons';
import { Button, IconButton, TextField } from '@material-ui/core';
import { UseErrorContext } from '../../global/contexts';
import Food from '../../assets/food.jpg';
import Shopping from '../../assets/shopping.jpg';
import Hotel from '../../assets/hotel.jpg';
import Adventure from '../../assets/adventure.jpg';
import Wellness from '../../assets/wellness.jpg';
import Other from '../../assets/other.jpg';
import Page from '../UI/Elements/Page';
import { getOrganizations, getCategories, createLead } from '../../api/services/organization';
import Organization from './Organization/Organization';
import { useHistory } from 'react-router';
import Modal from '../UI/Modal';


const Organizations = ({ search }) => {
  const classes = useStyles();
  const [organizations, setOrganizations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filterCategory, setFilterCategory] = useState(null);
  const [filterFromPrice, setFilterFromPrice] = useState(null);
  const [filterToPrice, setFilterToPrice] = useState(null);
  const [filterLocation, setFilterLocation] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showModalInvite, setShowModalInvite] = useState(false);
  const [inviteName, setInviteName] = useState("");
  const { updateError } = UseErrorContext();
  const history = useHistory();

  useEffect(() => {
    const refresh = async () => {
      try{        
        setLoading(true);

        let params = {
          'categoryId': filterCategory,
          'fromPrice': filterFromPrice,
          'toPrice': filterToPrice,
          'location': filterLocation,
          'status': "Actief",
        };
        if (search){
          params['search'] = search;
        }
        const data = await getOrganizations(params);        
        setOrganizations(data);
  
        setLoading(false);
      } catch (err) {
        console.log(err)
        //updateError(err);
      }
    };

    refresh();
  }, [currentPage, search, filterCategory, filterFromPrice, filterToPrice, filterLocation]);

  useEffect(() => {
    const initCategories = async () => {
      try{        
        setLoading(true);
        const data = await getCategories();    
        setCategories(data);
        setLoading(false);
      } catch (err) {
        console.log(err)
        //updateError(err);
      }
    };
    initCategories();
  }, []);

  const onChangeFilterCategory = (category) => {
    if (filterCategory !== category.id){
      setFilterCategory(category.id);
    }
    else{
      setFilterCategory(null);
    }
  }

  const onChangeFilterPrice = (fromPrice, toPrice) => {
    setFilterFromPrice(filterFromPrice !== fromPrice ? fromPrice : null);
    setFilterToPrice(filterToPrice !== toPrice ? toPrice : null);
  }
  
  const onChangeFilterLocation = (location) => {
    setFilterLocation(filterLocation !== location ? location : null);
  }

  const OpenAboutPage = () => {
    history.push("/over-ons");
  }

  const renderCategoryImage = (name) => {
    let source;

    switch(name) {
      case 'Eten & Drinken':
        source = Food; break;
      case 'Overnachten':
        source = Hotel; break;
      case 'Shopping':
        source = Shopping; break;
      case 'Overige':
        source = Other; break;
      case 'Sport':
        source = Adventure; break;
        case 'Beauty & Spa':
          source = Wellness; break;
    }

    return (
      <img src={source} alt={name} className={classes.categoryImg} width="auto"/>
    );
  }

  const _sendInvite = async () => {
    await createLead(inviteName);
    setInviteName("");
    setShowModalInvite(false);
  }

  return (
    <>
      {showModalInvite && (
        <Modal title={`Winkel uitnodigen`} onClose={() => setShowModalInvite(false)} onSubmit={() => _sendInvite()} cancelLabel={"Annuleer"} submitLabel={"Bevestig"}>
          <TextField label="Naam" onChange={(e) => setInviteName(e.target.value)} fullWidth value={inviteName}/>
        </Modal>
      )}  
      <div className={classes.kerst}>
        <div></div>
        <Page noBackground={true} className={classes.page}>
          <div className={classes.info}> 
          {/* Kerst */}
          {/* <h3>🎄 🎄 🎄 🎄 🎄 🎄 🎄 🎄 🎄</h3> */}
            <h3>Ontdek hier alle cadeaubonnen van jouw favoriete lokale winkels en handelaars!</h3><br></br>
            <p>Wij bieden een uitgebreid assortiment cadeaubonnen aan, variërend van lokale merken en retailers tot ervaringen en speciale diensten. Of u nu op zoek bent naar een cadeaubon voor een modewinkel, een elektronica-winkel, een restaurant of zelfs een avontuurlijke activiteit, u zult zeker iets vinden dat perfect aansluit bij de interesses van uw dierbaren.</p><br></br>
            <span>Wil jij ook jouw cadeaubonnen hier verkopen? </span><br></br>
            <IconButton className={classes.iconButton} size="small" onClick={() => OpenAboutPage()}>
              🚀 Nu lid worden!
            </IconButton>
          {/* Kerst */}
          {/* <h3>🎄 🎄 🎄 🎄 🎄 🎄 🎄 🎄 🎄</h3> */}
          </div>
          <div className={classes.topbar}>
              {categories.map((category, idx) => (   
                <div className={classes.categoryCard + `${filterCategory == category.id ? ' ' + classes.active : ''} `} key={idx} onClick={() => onChangeFilterCategory(category)}>
                  <div className={classes.categoryImgBox}>{renderCategoryImage(category.name)}</div>
                  <p className={classes.categoryText}>{category.name}</p>   
                </div>  
              ))}   
          </div>      
            <div className={classes.content}> 
              <div className={classes.filters}>
                <Button variant="outlined" className={`${(filterFromPrice == "0" && filterToPrice == "50") ? 'customButton' : 'customButton2'}`} startIcon={<Euro/>} onClick={() => onChangeFilterPrice(0, 50)}>Onder de 50</Button>
                <Button variant="outlined" className={`${(filterFromPrice == "50" && filterToPrice == "100") ? 'customButton' : 'customButton2'}`} startIcon={<Euro/>} onClick={() => onChangeFilterPrice(50, 100)}>Tussen de 50 en 100</Button>
                <Button variant="outlined" className={`${(filterFromPrice == "100" && filterToPrice == "0") ? 'customButton' : 'customButton2'}`} startIcon={<Euro/>} onClick={() => onChangeFilterPrice(100, 0)}>Boven de 100</Button>
                {/* <Button variant="outlined" className={`${filterLocation == "Antwerpen" ? 'customButton' : 'customButton2'} `} startIcon={<LocationOn/>} onClick={() => onChangeFilterLocation("Antwerpen")}>Antwerpen</Button> */}
              </div> 
              {loading 
              ? <Loader center />
              : <div className={classes.items}>         
                  <Grid container alignItems="stretch" className={classes.grid}>
                  { organizations.length === 0
                    ? <p>Geen resultaten gevonden</p>
                    : <>
                        {organizations.map((organization, idx) => (
                        <Grid key={organization.id} item xs={12} sm={6} md={4} lg={3}>
                          { organization 
                            ? (<Organization organization={organization} idx={idx}/>)
                            : (<Skeleton variant="rect" width={210} height={118} />)
                          }
                        </Grid>
                        ))}
                      </>
                  }
                  </Grid>
                  <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                </div>
              }
              <div className={classes.invite}>
                <IconButton className={classes.iconButton2} onClick={() => setShowModalInvite(true)}><span>Winkel of zaak niet gevonden? Nodig ze <u>hier</u> uit om deel te nemen!</span></IconButton>
              </div>
            </div>
        </Page>
      </div>
    </>
  );
};

export default Organizations;

