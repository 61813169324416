import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { useFormContext, Controller } from 'react-hook-form';
import { TextField, Grid } from '@material-ui/core';

function ReactHookFormSelect({name, label, defaultValue, required, fullWidth, children, ...props }) {
  const labelId = `${name}-label`;
  const { control } = useFormContext();

  return (
    <Grid item xs={12} sm={fullWidth ? 12 : 6}>
      <FormControl {...props} required={required} fullWidth>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Controller
          as={
            <Select labelId={labelId} label={label}>
              {children}
            </Select>
          }
          name={name}
          control={control}
          defaultValue={defaultValue}
          required={required}
          fullWidth  
        />
      </FormControl>
    </Grid>
  );
}

export default ReactHookFormSelect;